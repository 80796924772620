import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import TimeEntryCommittedEntity from "../entity";

export default class TimeEntryCommittedList extends DefaultController {
    tableElem: any;
    datatable: any;
    private editModal: any;

    async init() {
        this.entity = "time_entry_committeds";
        this.tableElem = jQuery('.datatables-users')
        this.editModal = new bootstrap.Modal((document.querySelector("#showTimeEntryCommitted") as HTMLElement));
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return TimeEntryCommittedEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                const oldConfig = Utils.timeTrack.generateList(data.config || []);
                const oldOverview = Utils.timeTrack.generateOverview(data.working, data.planned, data.pause, data.absences);
                this.editModal._element.querySelector("#time_entry_committed_old_config .time_entry_config").innerHTML = oldConfig;
                this.editModal._element.querySelector("#time_entry_committed_old_config .timeline-overview").innerHTML = oldOverview;
                if (data.status === "CORRECT_REQUESTED") {

                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.remove("col-6");
                    this.editModal._element.querySelector("#time_entry_committed_new_config").classList.remove("d-none");
                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.add("col-12");

                    const newConfig = Utils.timeTrack.generateList(data.config_new_calculated.config)
                    const newOverview = Utils.timeTrack.generateOverview(data.config_new_calculated.working, data.config_new_calculated.planned, data.config_new_calculated.pause, data.absences);
                    this.editModal._element.querySelector("#time_entry_committed_new_config .time_entry_config").innerHTML = newConfig;
                    this.editModal._element.querySelector("#time_entry_committed_new_config .timeline-overview").innerHTML = newOverview;

                    this.editModal._element.querySelector("#time_entry_committed_config").value = JSON.stringify(newConfig);

                    Utils.timeTrack.liveEdit(this.editModal._element.querySelector("#time_entry_committed_new_config .time_entry_config"), new Date(Date.parse(data.date)));
                } else {

                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.add("col-6");
                    this.editModal._element.querySelector("#time_entry_committed_new_config").classList.add("d-none");
                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.remove("col-12");
                    let html = '';
                    data.reasoning.forEach((reason: any) => {
                        let message = ''
                        if (reason["key"] === "MAX_HOURS_EXCEEDED") {
                            message = `Maximale Arbeitszeit überschritten. <br />${Utils.translate("timetrack.expected")}: ${Utils.msToHMS(parseInt(reason["expected"]) * 1000)}<br /> ${Utils.translate("timetrack.actual")}: ${Utils.msToHMS(parseInt(reason["actual"]) * 1000)}`
                        } else if (reason["key"] === "STAMP_TOO_EARLY" || reason["key"] === "STAMP_TOO_LATE") {
                            message = `${Utils.translate(`timetrack.${reason["key"].toLowerCase()}`)} <br />${Utils.translate("timetrack.expected")}: ${reason["expected"]}<br /> ${Utils.translate("timetrack.actual")}: ${reason["actual"]}`
                        }
                        html += `<div class="alert alert-danger" role="alert">${message}</div>`
                    })
                    this.editModal._element.querySelector("#time_entry_committed_messages .client_messages").innerHTML = html;
                    Utils.timeTrack.liveEdit(this.editModal._element.querySelector("#time_entry_committed_old_config .time_entry_config"), new Date(Date.parse(data.date)));
                }
                this.editModal.show();
            }
        });
        (document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const mode = (e.submitter as HTMLButtonElement).getAttribute("data-mode");
            const elem = document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            console.log("mode", mode)
            if (valid) {
                const r = await Utils.entity.upsert({...this.getEntityData(elem), status: mode, reasoning: []}, this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('time_entry_committed.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/time_entry_committeds`,
            columns: [
                {data: 'date'},
                {data: 'uuid'},
                {data: 'status'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user.name;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (data === "PENDING") {
                            return "Erfordert Genehmigung"
                        }else if (data === "CORRECT_REQUESTED") {
                            return "Änderung angefragt"
                        }
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-eye ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }

}